import './scss/_fonts.scss';
import './scss/_reset.scss';
import './scss/_global.scss';

import Vue from 'vue';
import VueI18n from 'vue-i18n';
import VueMeta from 'vue-meta';
import VueGtag from 'vue-gtag';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import getBrowserLocale from '@/util/get-browser-locale';
import SmoothScroll from 'smooth-scroll';
const scroll = new SmoothScroll('a[href*="#"]', {
  offset: 100,
  speed: 100
});

/* axios interceptor to change request url in craft live preview */
axios.interceptors.request.use(
  function(config) {
    let params = new URLSearchParams(document.location.search.substring(1));
    let token = params.get('token');
    if (token) {
      config.url = config.url + '?token=' + token;
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);
/* */

function getStartingLocale() {
  const browserLocale = getBrowserLocale({ countryCodeOnly: true });
  const localLang = window.localStorage.getItem('SKKG_LANG');

  if (localLang == 'de' || localLang == 'fr') {
    // console.log('local lang: ' + localLang);
    return localLang;
  }
  if (browserLocale == 'de' || browserLocale == 'fr') {
    // console.log('browser lang: ' + browserLocale);
    return browserLocale;
  } else {
    return 'de';
  }
}

Vue.use(VueMeta);
Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: getStartingLocale(),
  messages: require('../i18n/messages.json')
});

Vue.config.productionTip = false;

Vue.use(
  VueGtag,
  {
    config: { id: 'G-T8SPS0785M' }
  },
  router
);

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app');
