<template>
  <transition name="mm-slide" :duration="{ enter: 1000, leave: 600 }">
    <div class="nav" v-if="navVisible && menuData">
      <div class="nav__inner">
        <div class="nav__col">
          <nav class="main-nav">
            <ul>
              <li
                v-for="page in menuData.navigation.main"
                :key="page.slug"
                class="font-xl"
              >
                <router-link
                  :to="'/' + page.uri"
                  @click="$store.commit('closeNav')"
                >
                  {{ page.title }}
                </router-link>
              </li>
            </ul>
            <ul>
              <li
                v-for="page in menuData.navigation.secondary"
                :key="page.slug"
                class="font-xl"
              >
                <router-link
                  :to="'/' + page.uri"
                  @click="$store.commit('closeNav')"
                >
                  {{ page.title }}
                </router-link>
              </li>
            </ul>
          </nav>
          <nav class="sub-nav">
            <ul>
              <li
                v-for="page in menuData.navigation.small"
                :key="page.slug"
                class="font-lg"
              >
                <router-link
                  :to="'/' + page.uri"
                  @click="$store.commit('closeNav')"
                >
                  {{ page.title }}
                </router-link>
              </li>
            </ul>
          </nav>
          <div class="nav__social">
            <a
              href="https://www.instagram.com/skkg_winterthur"
              target="_blank"
              class="nav__social__link nav__social__link--insta"
            ></a>
            <a
              href="https://www.linkedin.com/company/skkg"
              target="_blank"
              class="nav__social__link nav__social__link--linkedin"
            ></a>
          </div>
        </div>
        <div class="nav__col">
          <ul class="nav__links">
            <li
              class="nav__links__item"
              v-for="link in menuData.sideLinks"
              :key="link.id"
            >
              <MenuLink :link="link.link">
                <span class="title font-spitzmarke">{{ link.title }}</span>
                <span class="text font-lg">{{ link.text }}</span>
              </MenuLink>
            </li>
          </ul>
          <ul class="nav__lang">
            <li
              v-for="(lang, i) in langs"
              :key="`Lang${i}`"
              :class="{ active: langIsActive(lang) }"
            >
              <a href="#" @click.prevent="setLang(lang)">{{ lang.substr(0, 1) }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import MenuLink from '@/components/MenuLink';

export default {
  name: 'Navigation',
  components: {
    MenuLink,
  },
  computed: {
    langs() {
      return this.$store.state.langs;
    },
    navVisible() {
      return this.$store.state.navVisible;
    },
    subpages() {
      return this.$store.state.subpages;
    },
    activeLang() {
      return this.$store.state.activeLang;
    },
    menuData() {
      return this.$store.state.menuData;
    },
  },
  watch: {
    $route: function (to, from) {
      this.$store.commit('closeNav');
    },
  },
  methods: {
    setLang(lang) {
      let _lang = lang;
      this.$store.dispatch('changeLanguage', {
        lang: _lang,
        i18n: this.$i18n,
      });
    },
    langIsActive(lang) {
      return lang == this.activeLang;
    },
    navigate(el) {
      this.$store.commit('closeNav');
      this.$router.push(el.target.getAttribute('href')).catch(() => {});
    },
  },
};
</script>

<style scoped lang="scss">
.nav {
  position: fixed;
  top: var(--header-height);
  right: 0;
  width: 100%;
  height: calc(100% - var(--header-height));
  background: #fff;
  padding: 0.5rem;
  padding-top: 2rem;
  box-sizing: border-box;
  z-index: 10;
  overflow-y: scroll;
  scroll-behavior: smooth;
  will-change: opacity;

  @include bp-s() {
    top: 0;
    height: 100%;
    padding-top: 0.5rem;
  }

  &__inner {
    display: flex;
    position: relative;
    padding-bottom: 3rem;
    min-height: 100%;
    box-sizing: border-box;
    will-change: transform, opacity;

    @include bp-s() {
      flex-direction: column;
    }
  }
  &__col {
    width: 50%;
    flex-grow: 0;

    @include bp-s() {
      width: 100%;
    }
  }
  &__links {
    max-width: 66.6%;

    &__item {
      margin-bottom: 1rem;
    }
    .title {
      display: block;
    }
    a:hover {
      text-decoration: none;
      .text {
        text-decoration: underline;
        text-decoration-thickness: 2px;
        text-underline-offset: 2px;
      }
    }
    @include bp-s() {
      max-width: 100%;
      margin-top: 2rem;
    }
  }
  &__social {
    display: flex;
    margin-top: 1rem;

    &__link {
      display: block;
      width: 2.25rem;
      height: 2.25rem;
      background-size: 100%;
      margin: 0 0.25rem;
      filter: invert(1);

      &:hover {
        filter: invert(0);
      }
      &--insta {
        background-image: url('../assets/share-home-insta.svg');
      }
      &--linkedin {
        background-image: url('../assets/share-home-linkedin.svg');
      }
    }
  }
}
.main-nav {
  max-width: calc(100% - 4rem);

  ul {
    margin-bottom: 1rem;
  }
  li {
    display: block;
    font-weight: bold;

    @media screen and (max-height: 400px) {
      font-size: 1rem;
      margin-bottom: 0.2rem;
    }
    @include bp-s() {
      font-size: 1.8rem;
    }
  }
  a {
    &:hover {
      text-decoration: underline;
      text-decoration-thickness: 2px;
      text-underline-offset: 2px;
    }
  }
}
.sub-nav {
  a {
    &:hover {
      text-decoration: underline;
      text-decoration-thickness: 2px;
      text-underline-offset: 2px;
    }
  }
  li {
    @include bp-s() {
      font-size: 0.8rem;
    }
  }

  @include bp-s() {
    display: none;
  }
}
.nav__lang {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  display: none;

  @include bp-s() {
    display: block;
  }

  li {
    text-transform: uppercase;
    display: inline-block;
    font-weight: bold;
    font-size: 1.3rem;

    &::after {
      content: '/';
      padding: 0 0.25rem;
    }
    &:last-child {
      &::after {
        display: none;
      }
    }
    &.active a {
      text-decoration: underline;
      text-decoration-thickness: 2px;
      text-underline-offset: 2px;
    }
  }
}

.mm-slide-enter-active {
  transition: 0.4s opacity;

  .nav__inner {
    transition: 0.6s opacity, 0.6s transform $easeOut;
    transition-delay: 0.3s;
  }
}
.mm-slide-leave-active {
  transition: 0.3s opacity;
  transition-delay: 0.3s;

  .nav__inner {
    transition: 0.3s opacity, 0.3s transform ease-in;
  }
}
.mm-slide-enter,
.mm-slide-leave-to {
  opacity: 0;

  .nav__inner {
    opacity: 0;
    transform: translateY(1rem);
  }
}
</style>
