<template>
  <li
    class="episode"
    :class="{ 'episode--active': active }"
    @mouseover="animating = true"
    @mouseout="animating = false"
    :style="{ backgroundColor: episode.color }"
  >
    <a href="#" class="mobile-toggle" @click.prevent="$emit('activate')"></a>
    <router-link :to="'/episodes/' + episode.nr" class="episode__link">
      <div class="episode__text">
        <span class="nr">
          <i>Episode</i>
          {{ episode.nr }}
        </span>
        <h3>{{ episode.headline }}</h3>
      </div>
      <div class="episode__imagewrap">
        <img
          class="episode__image"
          v-if="episode.animation"
          :src="animationUrl"
        />
        <canvas class="episode__canvas" ref="canv"></canvas>
      </div>
      <span
        class="episode__button"
        :style="{ backgroundColor: episode.colorSecondary }"
      >
        {{ episode.moreButtonText }}
      </span>
    </router-link>
  </li>
</template>

<script>
export default {
  props: ['episode', 'active'],
  data: () => {
    return {
      animating: true,
    };
  },
  mounted() {
    this.initCanvas();
  },
  methods: {
    initCanvas() {
      const canvas = this.$refs.canv;
      const ctx = canvas.getContext('2d');
      const img = new Image();
      img.src = this.episode.animation.url;
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
      };
    },
  },
  computed: {
    animationUrl() {
      if (this.animating || window.innerWidth < 540) {
        return this.episode.animation.url;
      } else {
        return '#';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.episode {
  grid-column: span 3;
  position: relative;

  h3 {
    font-weight: 900;
    font-size: 1.3rem;
    hyphens: auto;
    margin-top: 0.25rem;
  }
  .nr {
    text-transform: uppercase;
    font-size: 0.6rem;

    i {
      display: none;
    }
  }
  &__button {
    display: block;
    text-align: center;
    padding: 0.5rem;
    margin-top: 0.5rem;
    display: none;

    @include bp-xs() {
      display: block;
    }
  }
  &__imagewrap {
    position: relative;
    overflow: hidden;
    height: 0;
    padding-bottom: 100%;
  }
  &__image,
  &__canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    height: 100%;
    pointer-events: none;
  }
  &__image {
    display: none;
    image-rendering: crisp-edges;
  }
  &__link {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0.5rem;
    padding-top: 0.2rem;
    height: 100%;
    box-sizing: border-box;

    &:hover {
      .episode__image {
        display: block;
      }
      .episode__canvas {
        display: none;
      }
    }
  }

  @include bp-s() {
    grid-column: span 6;

    &__imagewrap {
      padding-bottom: 50%;
    }
    &__image,
    &__canvas {
      left: 50%;
      transform: translateX(-50%);
    }
  }
  @include bp-xs() {
    grid-column: span 12;
    max-height: 2.75rem;
    overflow: hidden;
    // max-width: 25rem;
    transition: 0.3s max-height ease-in-out;

    &.episode--active {
      max-height: 30rem;

      .episode__link {
        pointer-events: auto;
        padding-top: 0.4rem;
      }
      .nr {
        line-height: 1.2;
      }
    }

    h3 {
      font-size: 1.8rem;
      // margin-bottom: -1.5rem;
      z-index: 1;
      position: relative;
    }
    .nr {
      font-size: 0.7rem;
      letter-spacing: 0.05rem;
      line-height: 2.9rem;

      i {
        display: inline;
      }
    }
    &__link {
      pointer-events: none;
      padding-top: 0;
    }
    &__imagewrap {
      padding-bottom: 80%;
    }
    &__canvas {
      display: none;
    }
    &__image {
      display: block;
    }
  }
}
.mobile-toggle {
  display: none;
  @include bp-xs() {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    .episode--active & {
      display: none;
    }
  }
}
</style>