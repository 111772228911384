<template>
  <router-link
    v-if="link.type == 'entry'"
    :to="'/' + link.entry.uri"
    @click="$store.commit('closeNav')"
  >
    <slot />
  </router-link>
  <a v-else :href="link.url" @click="$store.commit('closeNav')">
    <slot />
  </a>
</template>

<script>
export default {
  name: 'MenuLink',
  props: ['link'],
};
</script>