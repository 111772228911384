import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    mobileMenuVisible: false,
    navVisible: false,
    apiUrl: null,
    home: null,
    episodes: [],
    subpages: [],
    stories: [],
    contentpages: [],
    globalData: null,
    menuData: null,
    activeLang: null,
    langs: ['de', 'fr'],
    transitionName: 'slide-right',
    isPreview: false,
    loadingCount: 0,
    faviconUrl: '/meta/favicon-32x32.png'
  },
  mutations: {
    setApiUrl(state, data) {
      let url = process.env.VUE_APP_APIURL
        ? process.env.VUE_APP_APIURL
        : 'http://sk01-cms.test/';
      url += 'api/' + state.activeLang + '/';
      state.apiUrl = url;
    },
    setLanguage(state, data) {
      state.activeLang = data;
    },
    toggleMobileMenu(state) {
      state.mobileMenuVisible = !state.mobileMenuVisible;
    },
    closeMobileMenu(state) {
      state.mobileMenuVisible = false;
    },
    toggleNav(state) {
      state.navVisible = !state.navVisible;
    },
    closeNav(state) {
      state.navVisible = false;
    },
    setTransitionName(state, data) {
      state.transitionName = data;
    },
    setFaviconUrl(state, data) {
      state.faviconUrl = data;
    },
    resetFaviconUrl(state) {
      state.faviconUrl = '/meta/favicon-32x32.png';
    },
    increaseLoadingCount(state) {
      state.loadingCount++;
    },
    decreaseLoadingCount(state) {
      state.loadingCount--;
    }
  },
  actions: {
    loadInitialData({ commit, dispatch }) {
      dispatch('checkPreview');
      dispatch('loadEpisodeData');
      dispatch('loadSubpageData');
      dispatch('loadContentData');
      dispatch('loadStoryData');
      dispatch('loadGlobalData');
      dispatch('loadMenuData');
      dispatch('loadHomeData');
    },
    loadEpisodeData({ state, commit }) {
      state.loadingCount++;

      axios.get(state.apiUrl + 'episodes').then(data => {
        state.loadingCount--;
        let eps = data.data.data;
        let hasFuture = false;
        if (!state.isPreview) {
          eps = eps.filter(e => {
            if (e.status == 'live') {
              return true;
            }
            if (!hasFuture) {
              hasFuture = true;
              return true;
            } else {
              return false;
            }
          });
        }

        state.episodes = eps;
      });
    },
    loadGlobalData({ state, commit }) {
      state.loadingCount++;
      axios.get(state.apiUrl + 'globals').then(data => {
        state.loadingCount--;
        state.globalData = data.data;
      });
    },
    loadMenuData({ state, commit }) {
      state.loadingCount++;
      axios.get(state.apiUrl + 'menu').then(data => {
        state.loadingCount--;
        state.menuData = data.data;
      });
    },
    loadSubpageData({ state, commit }) {
      state.loadingCount++;
      axios.get(state.apiUrl + 'subpages').then(data => {
        state.loadingCount--;
        state.subpages = data.data.data;
      });
    },
    loadContentData({ state, commit }) {
      state.loadingCount++;
      axios.get(state.apiUrl + 'content').then(data => {
        state.loadingCount--;
        state.contentpages = data.data.data;
      });
    },
    loadStoryData({ state, commit }) {
      state.loadingCount++;
      axios.get(state.apiUrl + 'stories').then(data => {
        state.loadingCount--;
        state.stories = data.data.data;
      });
    },
    loadHomeData({ state, commit }) {
      state.loadingCount++;
      axios.get(state.apiUrl + 'home').then(data => {
        state.loadingCount--;
        state.home = data.data;
      });
    },
    changeLanguage({ state, commit }, data) {
      window.localStorage.setItem('SKKG_LANG', data.lang);
      commit('setLanguage', data.lang);
      data.i18n.locale = data.lang;
    },
    checkPreview({ state, commit }) {
      const params = new URLSearchParams(document.location.search.substring(1));
      const token = params.get('x-craft-live-preview');
      if (token) {
        state.isPreview = true;
      }
    }
  },
  modules: {}
});
