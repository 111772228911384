<template>
  <div class="info"><img src="/info.gif"></div>
</template>

<script>
export default {
  name: 'Infoanlass',
  mounted() {
    window.location.href = 'https://us02web.zoom.us/j/85982143274';
  }
};
</script>

<style lang="scss">
@import '@/scss/_helpers';

.info {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 4rem;
  box-sizing: border-box;

  img {
    display: block;
    max-width: 90%;
    
  }
}
</style>