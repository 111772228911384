<template>
  <transition :name="transitionName" :duration="800" mode="out-in">
    <article
      id="info"
      :key="episode.nr"
      class="episode-info"
      :style="colorVars"
    >
      <a
        href="#"
        @click.prevent="scrollTop()"
        :class="{ totop: true, 'totop--visible': scrollTopVisible }"
      ></a>
      <h1>{{ episode.title }}</h1>
      <template v-for="block in episode.infoContent">
        <div
          class="episode-info__cols"
          :key="block.id"
          v-if="block.type == 'twoColumnLayout'"
        >
          <EpisodeInfoColumn
            :content="block.content.left"
            class="info-col--first"
          />
          <EpisodeInfoColumn
            :content="block.content.right"
            class="info-col--sec"
          />
        </div>
        <div
          class="episode-info__comic"
          :key="block.id"
          v-if="block.type == 'comic'"
        >
          <figure>
            <img
              class="episode-info__comic__desktop"
              :src="block.content.desktopImage.url"
              :width="block.content.desktopImage.width"
              :height="block.content.desktopImage.height"
            />
            <img
              class="episode-info__comic__mobile"
              :src="block.content.mobileImage.url"
              :width="block.content.mobileImage.width"
              :height="block.content.mobileImage.height"
            />
            <figcaption class="sr-only">{{ block.content.text }}</figcaption>
          </figure>
        </div>
      </template>
      <div
        class="episode-info__promo"
        id="promo"
        v-if="episode.promo.text"
        v-html="episode.promo.text"
      ></div>
    </article>
  </transition>
</template>

<script>
import EpisodeInfoColumn from './EpisodeInfoColumn';
import SmoothScroll from 'smooth-scroll';

export default {
  name: 'EpisodeInfo',
  props: ['episode'],
  components: {
    EpisodeInfoColumn,
  },
  data() {
    return {
      scrollListener: null,
      scrollTopVisible: false,
    };
  },
  watch: {
    episode() {
      this.$nextTick(() => {
        this.handleHashNavigation();
      });
    },
  },
  computed: {
    transitionName() {
      return this.$store.state.transitionName;
    },
    colorVars() {
      return {
        '--color-active': this.episode.color,
        '--color-bg': this.episode.colorSecondary,
        '--color-dark': this.episode.colorDark,
      };
    },
  },
  mounted() {
    this.scroll = new SmoothScroll();
    this.handleHashNavigation();
    this.scrollListener = document.addEventListener('scroll', () => {
      if (window.scrollY > window.innerHeight - 50) {
        this.scrollTopVisible = true;
      } else {
        this.scrollTopVisible = false;
      }
    });
  },
  beforeDestroy() {
    document.removeEventListener('scroll', this.scrollListener);
  },
  methods: {
    scrollTop() {
      this.scroll.animateScroll(0);
    },
    handleHashNavigation() {
      if (!this.$route.hash) return;
      if (this.loadingCount) return;
      let hash = this.$route.hash;
      if (document.querySelector(hash)) {
        setTimeout(() => {
          this.scroll.animateScroll(document.querySelector(hash), null, {
            offset: 100,
            speed: 100,
          });
        }, 200);
      }
    },
  },
};
</script>

<style lang="scss">


.episode-info {
  margin-top: calc(100vh - var(--header-height));
  position: relative;
  z-index: 2;
  padding: 1rem 0;
  padding-bottom: 2rem;
  box-sizing: border-box;
  background-color: var(--color-bg);
  padding-bottom: 2rem;
  // min-height: 100vh;

  @include bp-s() {
    margin-top: 0;
  }

  .totop {
    display: block;
    width: 3rem;
    height: 3rem;
    background: #fff;
    border-radius: 99%;
    position: fixed;
    top: calc(var(--header-height) + 1rem);
    right: 3rem;
    z-index: 7;
    opacity: 0;
    background-image: url('../assets/icon-arrow-up.svg');
    background-position: center;
    background-size: 35%;
    background-repeat: no-repeat;
    transition: 0.5s opacity;

    &--visible {
      opacity: 1;
    }
    @include bp-s() {
      display: none;
    }
  }

  &__cols {
    display: flex;
    flex-wrap: wrap;
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    background: var(--color-active);
    // background: #fff;
    transform: scale(0, 1);
  }

  &__comic {
    padding: 1rem;

    &__desktop,
    &__mobile {
      margin: 1rem auto;
      display: block;
      width: 100%;
      height: auto;
    }
    &__desktop {
      max-width: 50rem;

      @include bp-s() {
        display: none;
      }
    }
    &__mobile {
      display: none;
      max-width: 25rem;

      @include bp-s() {
        display: block;
      }
    }
  }
  &__promo {
    margin-top: 2rem;
    max-width: 50%;
    margin-left: 50%;
    padding: 0.3rem 0.5rem;
    box-sizing: border-box;
    background-color: #fff;

    h3 {
      font-weight: bold;
      margin: 0.25rem 0;
    }
    a {
      text-decoration: underline;
      text-decoration-color: var(--color-active);
    }
    p + p {
      margin-top: 1rem;
    }
    ul {
      margin: 1rem 0;
    }
    li {
      padding-left: 1.5rem;
      position: relative;
      line-height: 1.2;
      margin: 0.25rem 0;

      &::before {
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg width='27' height='17' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='%231D1D1B' stroke-width='.8' fill='none' fill-rule='evenodd'%3E%3Cpath d='M18.57 15.943l7.33-7.33-7.33-7.33M1 8.612h24.668'/%3E%3C/g%3E%3C/svg%3E");
        background-size: 100%;
        background-position: 0 50%;
        background-repeat: no-repeat;
        width: 1.2rem;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    @include bp-s() {
      max-width: none;
      margin-left: 0;
    }
  }
  h1 {
    font-size: 0.6rem;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
    padding: 0 1rem;

    @include bp-s() {
      padding: 0 0.5rem;
    }
  }
}

.slide-left-enter-active,
.slide-left-leave-active {
  &.episode-info::before {
    transition: transform 0.8s $easeInOut;
    transform-origin: 100% 0;
  }
}
.slide-right-enter-active,
.slide-right-leave-active {
  &.episode-info::before {
    transition: transform 0.8s $easeInOut;
    transform-origin: 0 0;
  }
}
.slide-right-enter, .slide-left-leave-to /* .fade-leave-active below version 2.1.8 */ {
  &.episode-info::before {
    transform: scale(1, 1);
    transform-origin: 0 0;
  }
}
.slide-left-enter, .slide-right-leave-to /* .fade-leave-active below version 2.1.8 */ {
  &.episode-info::before {
    transform: scale(1, 1);
    transform-origin: 100% 0;
  }
}
</style>
