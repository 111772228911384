<template>
  <div class="sharebtn">
    <transition name="share">
      <a
        v-if="!showButtons"
        class="sharebtn__btn"
        @click.prevent="showButtons = true"
        href="#"
      >
        <slot />
      </a>
      <div class="sharebtn__content" v-if="showButtons">
        <a
          target="_blank"
          :href="'https://www.facebook.com/sharer.php?u=' + pageUrl"
          class="sharebtn__social sharebtn__social--fb"
        ></a>
        <a
          target="_blank"
          :href="'https://twitter.com/intent/tweet?url=' + pageUrl"
          class="sharebtn__social sharebtn__social--twitter"
        ></a>
        <a
          target="_blank"
          :href="'https://www.linkedin.com/shareArticle?url=' + pageUrl"
          class="sharebtn__social sharebtn__social--linkedin"
        ></a>
        <a
          target="_blank"
          :href="'mailto:?body=' + pageUrl"
          class="sharebtn__social sharebtn__social--mail"
        ></a>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'ShareButton',
  data: () => {
    return {
      showButtons: false,
    };
  },
  watch: {
    $route: function (to, from) {
      this.showButtons = false;
    },
  },
  computed: {
    pageUrl() {
      return process.env.VUE_APP_SITEURL + this.$route.fullPath;
    },
  },
};
</script>

<style scoped lang="scss">


.sharebtn {
  background-color: #fff;
  display: block;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  margin-top: 0.5rem;
  height: 2rem;
  position: relative;
  overflow: hidden;
  transition: 0.5s box-shadow;
  box-shadow: 0 0 15px 2px rgba(#fff, 0);

  &:hover {
    box-shadow: 0 0 15px 2px rgba(#fff, 0.8);
  }

  &__btn {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    line-height: 2rem;
    padding-top: 0.05rem;

    &:hover {
      // text-decoration: underline;
    }
  }
  &__content {
    display: flex;
    justify-content: center;
  }
  &__social {
    display: block;
    width: 1.7rem;
    height: 2rem;
    margin: 0 0.25rem;
    background-size: 100%;
    background-position: center;

    &:hover {
      // opacity: .6;
    }

    &--fb {
      background-image: url('../assets/share-fb.svg');
    }
    &--twitter {
      background-image: url('../assets/share-twitter.svg');
    }
    &--linkedin {
      background-image: url('../assets/share-linkedin.svg');
    }
    &--mail {
      background-image: url('../assets/share-mail.svg');
    }
  }
}

.share-enter-active,
.share-leave-active {
  // transition: opacity .5s;
  transition: 0.5s transform $easeOut;
}
.share-enter, .share-leave-to /* .fade-leave-active below version 2.1.8 */ {
  // opacity: 0;
  transform: translateY(100%);
}
.share-leave-to {
  transform: translateY(-100%);
}
</style>
