export default [
  {
    path: '/team',
    redirect: { name: 'Subpage', hash: '#team', params: { slug: 'about' } }
  },
  {
    path: '/sammlung',
    redirect: {
      name: 'Subpage',
      hash: '#sammlung',
      params: { slug: 'about' }
    }
  },
  { path: '/about', redirect: '/pages/about' },
  { path: '/datenschutz', redirect: '/content/datenschutz' },
  { path: '/visite', redirect: '/content/visite' },
  {
    path: '/impressum',
    redirect: {
      name: 'Subpage',
      hash: '#impressum',
      params: { slug: 'contact' }
    }
  },
  { path: '/kontakt', redirect: '/pages/contact' },
  { path: '/events', redirect: '/stories/campo-info-architekturwettbewerb' },
  { path: '/exhibition', redirect: '/' },
  { path: '/campo', redirect: '/content/campo' },
  { path: '/tako', redirect: '/content/zusammen-neu-denken' },
  { path: '/infoanlass', redirect: '/content/infoanlass' },
  { path: '/brixe', redirect: '/stories/kunst-und-reinigung' },
  { path: '/dokumentarfilm', redirect: '/content/dokumentarfilm' },
  { path: '/podcast', redirect: '/stories/skkg-podcast' },
  {
    path: '/provenance',
    redirect: '/stories/provenienzforschung-bei-der-skkg-2-2'
  },
  {
    path: '/provenienz',
    redirect: '/stories/provenienzforschung-bei-der-skkg-2-2'
  },
  {
    path: '/begegnungstag',
    redirect: '/stories/100-jahre-bruno-stefanini'
  }
];
