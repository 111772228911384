<template>
  <div class="info-col">
    <template v-for="block in content">
      <div
        v-if="block.type == 'textBlock'"
        class="info-col__text"
        :class="{
          'info-col__text--border': block.border,
          'info-col__text--background': block.background,
        }"
        v-html="block.text"
        :key="block.id"
      ></div>
      <blockquote
        v-if="block.type == 'quoteBlock'"
        class="content__quote info-col__quote"
        :key="block.id"
      >
        <p>{{ block.text }}</p>
        <small>{{ block.author }}</small>
      </blockquote>
      <figure
        v-if="block.type == 'imageBlock' && block.image"
        class="content__image info-col__image"
        :key="block.id"
      >
        <img
          :src="block.image.url"
          :width="block.image.width"
          :height="block.image.height"
        />
        <figcaption v-html="block.caption"></figcaption>
      </figure>
      <ImageSlider
        v-if="block.type == 'imageSlider'"
        :key="block.id"
        :block="block"
      />
      <div
        v-if="block.type == 'videoBlock'"
        :key="block.id"
        class="content__video info-col__video"
      >
        <EpisodeInfoVideoPlayer :block="block" />
        <span class="caption" v-if="block.videoCaption">
          {{ block.videoCaption }}
        </span>
      </div>
      <hr
        v-if="block.type == 'dividerLine'"
        :key="block.id"
        class="info-col__hr"
      />
      <span
        v-if="block.type == 'anchor'"
        :key="block.id"
        :id="block.anchorId"
      />
      <span v-if="block.type == 'googleMapsEmbed'" :key="block.id">
        <iframe
          class="info-col__maps"
          :src="block.iframeUrl"
          width="600"
          height="450"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
        ></iframe>
      </span>
    </template>
  </div>
</template>

<script>
import EpisodeInfoVideoPlayer from '@/components/EpisodeInfoVideoPlayer';
import ImageSlider from '@/components/ImageSlider';

export default {
  name: 'episode-info-column',
  props: ['content'],
  components: {
    EpisodeInfoVideoPlayer,
    ImageSlider,
  },
};
</script>

<style lang="scss">


.info-col {
  margin-top: 1rem;
  flex-basis: 50%;
  width: 50%;

  @include bp-s() {
    flex-basis: 100%;
    width: 100%;
  }

  &__text {
    padding: 0 1rem;
    margin-bottom: 1rem;

    @include bp-s() {
      padding: 0 0.5rem;
    }
    .info-col--sec & {
      padding: 0 1rem 0 0;
      @include bp-s() {
        padding: 0 0.5rem;
      }
      &.info-col__text--border {
        padding: 0.5rem;
      }
    }

    h1,
    h2,
    h3 {
      font-weight: bold;
      line-height: 1.2;
    }
    h2 {
      font-size: 1.3rem;
      font-weight: bold;
      margin-bottom: 1rem;
    }
    h3 {
      margin-bottom: 0;
    }
    ul {
      // margin: .5rem 0;
    }
    li {
      padding-left: 1.5rem;
      position: relative;
      line-height: 1.2;
      margin: 0;

      &::before {
        content: '—';
        position: absolute;
        top: 0;
        left: 0;
      }
      /*padding-left: 1.5rem;
      position: relative;
      line-height: 1.2rem;
      margin: 0.25rem 0;

      &::before {
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg width='27' height='17' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='%231D1D1B' stroke-width='.8' fill='none' fill-rule='evenodd'%3E%3Cpath d='M18.57 15.943l7.33-7.33-7.33-7.33M1 8.612h24.668'/%3E%3C/g%3E%3C/svg%3E");
        background-size: 100%;
        background-position: 0 50%;
        background-repeat: no-repeat;
        width: 1.2rem;
        height: 1.2rem;
        position: absolute;
        top: 0;
        left: 0;
      }*/
    }
    p + h3,
    ul + p,
    ul + h3 {
      margin-top: 1rem;
    }
    p.lead {
      font-size: 1.3rem;
      line-height: 1.15;
    }
    a {
      text-decoration: underline;
      text-decoration-color: #fff;
    }
    &--border {
      border: 2px solid #fff;
      box-sizing: border-box;
      padding: 0.5rem;
      margin-left: 0.5rem;
      margin-right: 0.5rem;
    }
    &--background {
      background: #fff;
      box-sizing: border-box;
      padding: 1rem;

      a {
        text-decoration-color: var(--color-active);
      }
    }
  }

  &__hr {
    display: block;
    color: #fff;
    background-color: #fff;
    border: none;
    height: 2px;
    margin: 4.4rem 0 4.5rem 0;

    @include bp-s() {
      margin: 3rem 0;
    }
  }

  &__maps {
    margin: 1rem 0;
    width: 100%;
    height: 20rem;
  }
}
</style>
