<template>
  <div class="error">404</div>
</template>

<script>
export default {
  name: 'NotFoundComponent',
};
</script>

<style lang="scss">
@import '@/scss/_helpers';

.error {
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 5vw;
  transform: translate(-50%, -50%);
}
</style>