<template>
  <figure class="headerlogo">
    <img
      :src="transitionUrl"
      v-if="transitioning"
      class="headerlogo__transition"
    />
    <img :src="logoUrl" v-if="logoUrl" />
  </figure>
</template>

<script>
export default {
  name: 'HeaderLogo',
  data() {
    return {
      transitioning: false,
      logoIndex: 0,
      transitionIndex: 0,
      randomHash: 0,
    };
  },
  computed: {
    globalData() {
      return this.$store.state.globalData;
    },
    logoImages() {
      if (!this.$store.state.globalData) return [];
      let l = this.$store.state.globalData.logoImages;
      l.unshift('/skkg-item.svg');
      return l;
    },
    logoTransitions() {
      if (!this.$store.state.globalData) return [];
      return this.$store.state.globalData.logoTransitions;
    },
    logoUrl() {
      if (!this.logoImages) return '/skkg-item.svg';
      return this.logoImages[this.logoIndex];
    },
    transitionUrl() {
      if (!this.logoTransitions) return '';
      return this.logoTransitions[this.transitionIndex] + '?' + this.randomHash;
    },
  },
  mounted() {
    window.setTimeout(() => {
      this.transitionLogo();
    }, 5000);
  },
  methods: {
    transitionLogo() {
      if(this.logoImages.length < 2) return;
      
      let timer = Math.round(Math.random() * 30000) + 5000;

      const generateLogoIndex = () => {
        let i = Math.floor(Math.random() * this.logoImages.length);
        if (i == this.logoIndex) {
          return generateLogoIndex();
        } else {
          return i;
        }
      };

      this.transitioning = true;
      this.randomHash = Math.round(Math.random() * 100000);
      this.transitionIndex = Math.floor(
        Math.random() * this.logoTransitions.length
      );
      // this.transitionIndex = 1;

      window.setTimeout(() => {
        this.logoIndex = generateLogoIndex();
      }, 300);
      window.setTimeout(() => {
        this.transitioning = false;
      }, 1300);
      window.setTimeout(() => {
        this.transitionLogo();
      }, timer);
    },
  },
};
</script>

<style scoped lang="scss">


.headerlogo {
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  width: 2.8rem;
  height: 2.8rem;
  margin-top: -1.4rem;
  background-image: url('../assets/skkg-logo.svg');
  background-size: 100%;

  img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  &__transition {
    position: relative;
    z-index: 1;
  }
}
</style>
