<template>
  <transition name="mm-slide" :duration="{ enter: 1000, leave: 400 }">
    <div class="mobilemenu" v-if="mobileMenuVisible">
      <router-link to="/" class="home-link"><span>Home</span></router-link>
      <nav class="mobile-nav">
        <div v-for="page in subpages" :key="page.id">
          <ul>
            <li v-for="child in page.children" :key="child.id">
              <a
                :href="'/pages/' + page.slug + '#' + child.slug"
                @click.prevent="navigate"
              >
                {{ child.title }}
              </a>
            </li>
          </ul>
        </div>
        <div>
          <ul>
            <li><router-link to="/content/campo">campo</router-link></li>
          </ul>
        </div>
      </nav>
      <ul class="nav-lang">
        <li
          v-for="(lang, i) in langs"
          :key="`Lang${i}`"
          :class="{ active: langIsActive(lang) }"
        >
          <a href="#" @click.prevent="setLang(lang)">{{ lang.substr(0, 1) }}</a>
        </li>
      </ul>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'MobileMenu',
  computed: {
    langs() {
      return this.$store.state.langs;
    },
    mobileMenuVisible() {
      return this.$store.state.mobileMenuVisible;
    },
    subpages() {
      return this.$store.state.subpages;
    },
    activeLang() {
      return this.$store.state.activeLang;
    },
  },
  watch: {
    $route: function (to, from) {
      this.$store.commit('closeMobileMenu');
    },
  },
  methods: {
    setLang(lang) {
      let _lang = lang;
      this.$store.dispatch('changeLanguage', {
        lang: _lang,
        i18n: this.$i18n,
      });
    },
    langIsActive(lang) {
      return lang == this.activeLang;
    },
    navigate(el) {
      this.$store.commit('closeMobileMenu');
      this.$router.push(el.target.getAttribute('href')).catch(() => {});
    },
  },
};
</script>

<style scoped lang="scss">


.mobilemenu {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 2rem 0.5rem 0.5rem 0.5rem;
  box-sizing: border-box;
  z-index: 8;
  overflow-y: scroll;
  scroll-behavior: smooth;

  @include bp-s() {
    display: block;
  }
}
.home-link {
  display: block;
  width: 3rem;
  height: 3rem;
  background-image: url('../assets/heart-back.svg');
  background-size: 100%;
  margin: 1rem 0 -0.5rem 0;
  text-align: center;

  span {
    line-height: 2.7rem;
    font-size: 0.65rem;
    width: 100%;
  }
}
.mobile-nav {
  max-width: calc(100% - 4rem);

  ul {
    margin: 1rem 0;
  }
  li {
    display: block;
    font-size: 1.5rem;
    font-weight: bold;
    // white-space: nowrap;

    @media screen and (max-height: 400px) {
      font-size: 1rem;
      margin-bottom: 0.2rem;
    }
  }
  /*h2 {
    font-size: .8rem;
    text-transform: uppercase;
    letter-spacing: .05rem;
    margin: 1rem 0 0 0;
  }*/
}
.nav-lang {
  margin-top: 1.2rem;

  li {
    text-transform: uppercase;
    display: inline-block;
    font-weight: bold;
    font-size: 1.5rem;

    @media screen and (max-height: 380px) {
      font-size: 1rem;
    }

    &::after {
      content: '/';
      margin: 0 0.25rem;
    }
    &:last-child {
      &::after {
        display: none;
      }
    }
    &.active a {
      text-decoration: underline;
    }
  }
}

.mm-slide-enter-active {
  transition: 0.5s transform $easeOut;

  .mobile-nav,
  .nav-lang,
  .heart-back {
    transition: 1s transform $easeOut;
  }
}
.mm-slide-leave-active {
  transition: 0.4s transform $easeIn;

  .mobile-nav,
  .nav-lang,
  .heart-back {
    transition: 0.4s transform $easeIn;
  }
}
.mm-slide-enter, .mm-slide-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translateY(-100%);

  .mobile-nav,
  .nav-lang,
  .heart-back {
    transform: translateY(-100%);
  }
}
</style>
