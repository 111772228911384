<template>
  <router-link class="heart" :to="to"><slot /></router-link>
</template>

<script>
export default {
  props: ['to'],
};
</script>

<style lang="scss">
.heart {
  display: block;
  position: fixed;
  top: 5rem;
  right: 1rem;
  background-image: url('../../assets/heart-nl.svg');
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  width: 6rem;
  height: 5.5rem;
  font-size: .8rem;
  padding-top: 1.5rem;
  box-sizing: border-box;
  text-align: center;
  // filter: drop-shadow(0 0 10px rgba(#000, 0));
  z-index: 1;
  transition: .2s filter;

  &:hover {
    // text-decoration: underline;
    // filter: drop-shadow(0 0 10px rgba(#f00, .2));
  }

  @include bp-s() {
    position: static;
    margin: -1rem auto 3rem auto;
  }
}
</style>